import React, { Fragment, useCallback, useState, useEffect } from "react"
import Layout from "components/common/layout.js"
// import Layout from "components/member/signUp/layout.js"
import * as styles from "pages/class/lesson/list.module.css"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { getLessonListAsync } from "store/modules/postReducer.js"
import OrderMenu from "components/tools/orderMenu"
import Pagenation from "components/tools/pagination.js"
import Lesson from "components/post/lesson.js"
import MenuContainer from "components/tools/menu/Container.js"
import LessonMenuCompo from "components/tools/menu/lesson.js"
import { permission } from "utils"
//
import { lessonMenu } from "utils/data"
import NewBadge from "components/tools/newBadge"
import queryString from "query-string"
import { navigate } from "gatsby"
const List = props => {
  const [order, setOrder] = useState(1)
  const { page } = queryString.parse(props.location.search)
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1)
  const pagePostSize = 16

  const [checkedList, setCheckedList] = useState({
    course: [],
    curriculum: [],
    kind: [],
  })

  const [searchFlag, setSearchFlag] = useState(false)

  const searchToggle = () =>
    searchFlag ? setSearchFlag(false) : setSearchFlag(true)

  const dispatch = useDispatch()
  const getLessonList = useCallback(
    (currentPage, pagePostSize, order, option) =>
      dispatch(getLessonListAsync(currentPage, pagePostSize, order, option)),
    [dispatch]
  )

  const keys = ["course", "curriculum", "kind"]

  const { lessonList, lessonTotal } = useSelector(
    ({ postReducer: { lesson } }) => lesson,
    shallowEqual
  )
  console.log(lessonTotal)
  useEffect(() => {
    ;(async () => {
      const setKey = key =>
        checkedList[key].map(
          elem =>
            Array.from(lessonMenu.get(key).contents.keys()).indexOf(elem) + 1
        )

      const checkListMap = {
        [keys[0]]: setKey(keys[0]),
        [keys[1]]: setKey(keys[1]),
        [keys[2]]: setKey(keys[2]),
      }

      await getLessonList(currentPage, pagePostSize, order, checkListMap)
    })()
  }, [order, currentPage, searchFlag])

  console.log(lessonList)

  return (
    <Layout>
      <div className={styles.con}>
        <div className={styles.title} style={{ marginTop: "60px" }}>
          <p className={`${styles.subtitle}`}>수업나눔</p>
          <div>스토리를 활용한 선생님의 수업을 함께 나누어요.</div>
        </div>
        {/* menu */}
        <div className={styles.menu}>
          {keys.map((key, i) => (
            <Fragment key={i}>
              <div style={{ marginLeft: "50px" }}>
                <MenuContainer
                  separ={key}
                  checkedList={checkedList}
                  setCheckedList={setCheckedList}
                  child={<LessonMenuCompo checkedList={checkedList} />}
                />
              </div>
              <hr />
            </Fragment>
          ))}

          <div
            style={{ display: "block" }}
            className={`greenBtn ${styles.search}`}
            onClick={() => {
              setCurrentPage(1)
              searchToggle()
            }}
          >
            검색
          </div>
          <OrderMenu
            order={order}
            setOrder={setOrder}
            setCurrentPage={setCurrentPage}
          />
          <div className={styles.btnposition}>
            <WriteBtn />
          </div>

          {/* 리스트 */}
          <div className={styles.main}>
            {lessonList.map((post, i) => (
              <div key={i}>
                <Lesson post={post} />
              </div>
            ))}
          </div>
          <div className={styles.btnposition2}>
            <WriteBtn />
          </div>
          {/* 리스트 끝 */}
          <div className={styles.pg} />
          <div
            style={{
              display: "block",
            }}
          >
            <Pagenation
              total={lessonTotal}
              pagePostSize={pagePostSize}
              pageSize={10}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default List
const WriteBtn = () => {
  return (
    <div
      className={`greenBtn ${styles.write}`}
      onClick={() => {
        permission("/class/lesson/write/")
      }}
    >
      수업나눔 작성하기
    </div>
  )
}
