// extracted by mini-css-extract-plugin
export var con = "list-module--con--3XTPO";
export var title = "list-module--title--bKIwC";
export var red = "list-module--red--1Xmt-";
export var subnotice = "list-module--subnotice--22Gft";
export var subtitle = "list-module--subtitle--G3dUX";
export var menu = "list-module--menu--128KC";
export var search = "list-module--search--2G90k";
export var main = "list-module--main--UlG7N";
export var write = "list-module--write--3988f";
export var pg = "list-module--pg--3xagd";
export var btnposition = "list-module--btnposition--3e76f";
export var btnposition2 = "list-module--btnposition2--1skLw";